import React, { useState, useCallback } from 'react';
import { useData } from '../../context/DataContext';
import { Search, Loader2, RefreshCw, Plus, Trash2 } from 'lucide-react';
import FileUploader from './FileUploader';
import TableActions from './TableActions';
import CountBadge from './CountBadge';
import FindReplace from './FindReplace';
import { debounce } from '../../lib/utils';

const CriteriaTab = () => {
  const { criteria, loadData, updateData, loading } = useData();
  const [searchTerm, setSearchTerm] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  const handleLoadData = async () => {
    if (!isInitialized) {
      await loadData('criteria');
      setIsInitialized(true);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 300),
    []
  );

  const handleFindReplace = async (find: string, replace: string) => {
    try {
      const updatedCriteria = criteria.map(item => ({
        ...item,
        guideline: item.guideline.replace(new RegExp(find, 'g'), replace)
      }));
      await updateData('criteria', updatedCriteria);
    } catch (error) {
      console.error('Error updating criteria:', error);
    }
  };

  const filteredCriteria = criteria.filter(item => {
    const search = searchTerm.toLowerCase();
    return (
      item.guideline?.toString().toLowerCase().includes(search) ||
      item.criteria?.toString().toLowerCase().includes(search)
    );
  });

  if (loading['criteria']) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <CountBadge count={criteria.length} label="Total Criteria" />

      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search criteria..."
            onChange={(e) => debouncedSearch(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div className="flex items-center space-x-4">
          {!isInitialized && (
            <button
              onClick={handleLoadData}
              className="flex items-center px-3 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Load Data
            </button>
          )}
          <FindReplace onReplace={handleFindReplace} />
          <FileUploader type="criteria" />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Guideline
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Criteria
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredCriteria.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-normal text-sm text-gray-900">
                    {item.guideline}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">
                    {item.criteria}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                    <button
                      onClick={async () => {
                        const newCriteria = criteria.filter((_, i) => i !== index);
                        await updateData('criteria', newCriteria);
                      }}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              ))}
              {filteredCriteria.length === 0 && (
                <tr>
                  <td colSpan={3} className="px-6 py-4 text-center text-sm text-gray-500">
                    No criteria found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CriteriaTab;