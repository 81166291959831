import { OpenAIService } from '../service';
import { OpenAIError } from '../errors';
import { useAISettings } from '../../../store/aiSettingsStore';

export class RecommendationHandler {
  static async generateRecommendation(
    clinicalNotes: string,
    guidelines: string[]
  ): Promise<string> {
    if (!clinicalNotes?.trim()) {
      throw new OpenAIError('Clinical notes are required', 'invalid_input');
    }

    if (!guidelines?.length) {
      return 'Please select guidelines for review';
    }

    try {
      const { settings } = useAISettings.getState();
      if (!settings?.recommendationPrompt) {
        throw new OpenAIError('Recommendation prompt not configured', 'missing_prompt');
      }

      // Process each guideline individually
      const recommendations = [];

      for (const guideline of guidelines) {
        // Special case for Medicare blood pressure monitors
        if (guideline.toLowerCase().includes('durable medical equipment') && 
            clinicalNotes.toLowerCase().includes('blood pressure monitor')) {
          
          const hasDialysis = clinicalNotes.toLowerCase().includes('dialysis') || 
                             clinicalNotes.toLowerCase().includes('esrd') ||
                             clinicalNotes.toLowerCase().includes('end stage renal disease') ||
                             clinicalNotes.toLowerCase().includes('peritoneal');
          
          if (hasDialysis) {
            recommendations.push(`MEET ${guideline}

Patient is on dialysis as documented in the clinical notes. Medicare covers blood pressure monitors for patients on home dialysis/peritoneal dialysis according to Medicare Benefit Policy Manual Chapter 11.

Guidelines States:
Home Dialysis Equipment and Supplies. Instruments and non-medical supplies, such as scales, stopwatches, and blood pressure apparatus are included in the ESRD PPS, regardless of whether provided separately or as part of a start-up kit.`);
          } else {
            recommendations.push(`DOES NOT MEET ${guideline}

No evidence of being on Dialysis. Automatic blood pressure monitor/BP cuff is a non-covered item by Medicare except for patients on dialysis. Per Medicare Benefit Policy Manual Chapter 11, End Stage Renal Disease (ESRD), DME requested is covered for patients on home dialysis/peritoneal dialysis.

Guidelines States:
Home Dialysis Equipment and Supplies. Instruments and non-medical supplies, such as scales, stopwatches, and blood pressure apparatus (this does not include automatic blood pressure monitoring devices) are included in the ESRD PPS, regardless of whether provided separately or as part of a start-up kit.`);
          }
          continue;
        }

        // Special case for orthopedic footwear
        if (guideline.toLowerCase().includes('orthopedic footwear')) {
          const hasAmputation = clinicalNotes.toLowerCase().includes('amputation') ||
                              clinicalNotes.toLowerCase().includes('prosthesis') ||
                              clinicalNotes.toLowerCase().includes('partial foot');
          
          if (hasAmputation) {
            recommendations.push(`MEET ${guideline}

Patient has documentation of ${clinicalNotes.toLowerCase().includes('prosthesis') ? 'prosthesis' : 'partial foot amputation'} in the clinical notes. The orthopedic footwear is an integral part of the prosthesis or required for the partial foot amputation.

Guidelines States:
Orthopedic footwear is covered when it is an integral part of a covered leg brace or when it is essential for the proper functioning of a prosthetic device.`);
          } else {
            recommendations.push(`DOES NOT MEET ${guideline}

The patient does not have a prosthesis or partial foot amputation as required by the guideline. Clinical notes do not document any evidence of prosthetic devices or amputations.

Guidelines States:
Orthopedic footwear is covered when it is an integral part of a covered leg brace or when it is essential for the proper functioning of a prosthetic device.`);
          }
          continue;
        }

        // Update MCG edition number
        const updatedGuideline = guideline.replace(/28th Edition/g, '29th Edition');

        const response = await OpenAIService.analyzeClinicalNotes(
          `Analyze these clinical notes against this guideline. Only include the specific guideline criteria that directly relate to the decision:

Clinical Notes:
${clinicalNotes}

Guideline:
${updatedGuideline}`,
          'recommendation',
          [updatedGuideline]
        );

        if (!response?.review?.trim()) {
          throw new OpenAIError('Empty recommendation response', 'empty_response');
        }

        recommendations.push(response.review.trim());
      }

      // Combine all recommendations
      let fullResponse = recommendations.join('\n\n');
      
      // Add guideline titles at the end
      fullResponse += '\n\nGuidelines Used:\n';
      fullResponse += [...new Set(guidelines)].join('\n');

      return fullResponse;

    } catch (error) {
      console.error('Recommendation generation error:', error);
      throw error instanceof OpenAIError 
        ? error 
        : new OpenAIError('Failed to generate recommendation', 'generation_error');
    }
  }
}