import React, { useEffect } from 'react';
import { SummaryDisplay } from './SummaryDisplay';
import { useSummaryGeneration } from './hooks/useSummaryGeneration';
import type { SummarySectionProps } from './types';

const SummarySection: React.FC<SummarySectionProps> = ({ 
  text,
  enabled = true
}) => {
  const { 
    summary, 
    keyFindings, 
    recommendations,
    isLoading, 
    error,
    generateSummary 
  } = useSummaryGeneration();

  useEffect(() => {
    if (enabled && text?.trim()) {
      generateSummary(text);
    }
  }, [text, enabled, generateSummary]);

  if (!enabled || !text?.trim()) return null;

  return (
    <SummaryDisplay
      summary={summary}
      keyFindings={keyFindings}
      recommendations={recommendations}
      isLoading={isLoading}
      error={error}
      autoPlay={true}
    />
  );
};

export default SummarySection;