import { getDB } from '../lib/storage/db';
import { logError, logInfo } from '../utils/logging';

export class LocalStorageService {
  private static initialized = false;
  private static initializationPromise: Promise<void> | null = null;

  private static async initialize() {
    if (this.initialized) return;
    if (this.initializationPromise) return this.initializationPromise;

    this.initializationPromise = (async () => {
      try {
        await getDB();
        this.initialized = true;
      } catch (error) {
        logError('Error initializing database:', error);
        throw error;
      } finally {
        this.initializationPromise = null;
      }
    })();

    return this.initializationPromise;
  }

  static async getData(collectionName: string): Promise<any[]> {
    try {
      await this.initialize();
      const db = await getDB();
      
      // Verify store exists
      if (!db.objectStoreNames.contains(collectionName)) {
        logError(`Store ${collectionName} not found in database`);
        return [];
      }
      
      const tx = db.transaction(collectionName, 'readonly');
      const store = tx.objectStore(collectionName);
      const items = await store.getAll();
      
      // Filter out initialization record
      const filteredItems = items.filter(item => item.id !== 'init');
      
      logInfo(`Retrieved ${filteredItems.length} items from ${collectionName}`);
      return filteredItems;
    } catch (error) {
      logError(`Error getting data from ${collectionName}:`, error);
      return [];
    }
  }

  static async setData(collectionName: string, data: any[]): Promise<void> {
    try {
      await this.initialize();
      const db = await getDB();
      
      // Verify store exists
      if (!db.objectStoreNames.contains(collectionName)) {
        logError(`Store ${collectionName} not found in database`);
        return;
      }
      
      const tx = db.transaction(collectionName, 'readwrite');
      const store = tx.objectStore(collectionName);
      
      // Clear existing data except initialization record
      const allKeys = await store.getAllKeys();
      for (const key of allKeys) {
        if (key !== 'init') {
          await store.delete(key);
        }
      }
      
      // Add new data
      for (const item of data) {
        if (!item.id) {
          item.id = crypto.randomUUID();
        }
        await store.add(item);
      }
      
      await tx.done;
      logInfo(`Saved ${data.length} items to ${collectionName}`);
    } catch (error) {
      logError(`Error saving data to ${collectionName}:`, error);
      throw error;
    }
  }

  static async clearData(collectionName: string): Promise<void> {
    try {
      await this.initialize();
      const db = await getDB();
      
      // Verify store exists
      if (!db.objectStoreNames.contains(collectionName)) {
        logError(`Store ${collectionName} not found in database`);
        return;
      }
      
      const tx = db.transaction(collectionName, 'readwrite');
      const store = tx.objectStore(collectionName);
      
      // Clear all data except initialization record
      const allKeys = await store.getAllKeys();
      for (const key of allKeys) {
        if (key !== 'init') {
          await store.delete(key);
        }
      }
      
      await tx.done;
      logInfo(`Cleared all data from ${collectionName}`);
    } catch (error) {
      logError(`Error clearing data from ${collectionName}:`, error);
      throw error;
    }
  }

  static async clearAll(): Promise<void> {
    try {
      await this.initialize();
      const db = await getDB();
      
      const stores = Array.from(db.objectStoreNames);
      for (const store of stores) {
        const tx = db.transaction(store, 'readwrite');
        const objectStore = tx.objectStore(store);
        
        // Clear all data except initialization records
        const allKeys = await objectStore.getAllKeys();
        for (const key of allKeys) {
          if (key !== 'init') {
            await objectStore.delete(key);
          }
        }
        
        await tx.done;
      }
      
      logInfo('Cleared all data from database');
    } catch (error) {
      logError('Error clearing all data:', error);
      throw error;
    }
  }
}