import React, { useState, useEffect, useRef } from 'react';
import ReviewForm from '../components/ReviewForm';
import ReviewOutput from '../components/ReviewOutput';
import ReviewCounter from '../components/ReviewCounter';
import ReviewTimer from '../components/ReviewTimer';
import ReviewHistory from '../components/ReviewHistory';
import GuidelineProcessor from '../components/admin/GuidelineProcessor';
import AIChat from '../components/AIChat';
import { Review } from '../types';
import { useAuth } from '../context/AuthContext';
import { extractPatientName } from '../utils/patientNameExtractor';
import { useReviewHistoryStore } from '../store/reviewHistoryStore';
import { useFormStore } from '../store/formStore';
import { Eraser } from 'lucide-react';

const Dashboard = () => {
  const { user } = useAuth();
  const { addReview } = useReviewHistoryStore();
  const { clearForm } = useFormStore();
  const [review, setReview] = useState<Review>({
    reasonForRequest: '',
    dateOfService: new Date().toISOString(),
    medicalHistory: '',
    criteriaApplied: [],
    recommendation: '',
    signature: user?.isAdmin ? 'C. Starks, LVN' : `${user?.name || ''}, ${user?.credentials || ''}`,
    additionalNote: 'N/A, current attached clinical notes reviewed.'
  });
  const [selectedCptCodes, setSelectedCptCodes] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [patientName, setPatientName] = useState<string>('');
  const [showReview, setShowReview] = useState(false);
  const [clinicalNotes, setClinicalNotes] = useState('');
  const reviewOutputRef = useRef<HTMLDivElement>(null);
  const clinicalNotesRef = useRef<HTMLDivElement>(null);

  const handleReviewUpdate = (newReview: Review, cptCodes: string[]) => {
    setReview({
      ...newReview,
      signature: user?.isAdmin ? 'C. Starks, LVN' : `${user?.name || ''}, ${user?.credentials || ''}`
    });
    setSelectedCptCodes(cptCodes);
    setError(null);
    setShowReview(true);

    // Add to review history if patient name exists
    if (patientName) {
      addReview(patientName, newReview);
    }

    // Scroll to review output
    setTimeout(() => {
      reviewOutputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };

  const handleDecisionUpdate = (newDecision: string) => {
    setReview(prev => ({
      ...prev,
      recommendation: newDecision
    }));
  };

  const handleClinicalNotesChange = (notes: string) => {
    const name = extractPatientName(notes);
    setPatientName(name || '');
    setShowReview(false);
    setClinicalNotes(notes);
  };

  const handleClearAll = () => {
    // Clear form store state
    clearForm();
    
    // Reset local state
    setReview({
      reasonForRequest: '',
      dateOfService: new Date().toISOString(),
      medicalHistory: '',
      criteriaApplied: [],
      recommendation: '',
      signature: user?.isAdmin ? 'C. Starks, LVN' : `${user?.name || ''}, ${user?.credentials || ''}`,
      additionalNote: 'N/A, current attached clinical notes reviewed.'
    });
    setSelectedCptCodes([]);
    setError(null);
    setPatientName('');
    setShowReview(false);
    setClinicalNotes('');

    // Scroll to clinical notes section
    setTimeout(() => {
      clinicalNotesRef.current?.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start' 
      });
    }, 100);
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 animate-[gradient_4s_ease_infinite] py-6">
        <div className="max-w-7xl mx-auto px-4">
          <div className="relative">
            <div className="absolute inset-0 bg-red-500/20 rounded-2xl blur-xl"></div>
            <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-6 rounded-2xl">
              <p className="text-red-300">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 animate-[gradient_4s_ease_infinite] py-6">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center mb-6">
          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-lg"></div>
            <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-4 rounded-xl">
              <ReviewCounter />
            </div>
          </div>
          
          {patientName && (
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-lg"></div>
              <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-4 rounded-xl">
                <h2 className="text-xl font-bold text-white">{patientName}</h2>
              </div>
            </div>
          )}

          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-lg"></div>
            <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-4 rounded-xl">
              <ReviewTimer />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="lg:sticky lg:top-6 lg:self-start space-y-6">
            <div className="relative group" ref={clinicalNotesRef}>
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 rounded-2xl blur-xl"></div>
              <div className="relative backdrop-blur-md bg-black/30 border border-white/20 p-6 rounded-2xl">
                <h2 className="text-2xl font-bold text-white mb-4">Clinical Review Input</h2>
                <ReviewForm 
                  onReviewGenerate={handleReviewUpdate}
                  onClinicalNotesChange={handleClinicalNotesChange}
                />
              </div>
            </div>
            
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-2xl blur-xl"></div>
              <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-6 rounded-2xl">
                <h2 className="text-2xl font-bold text-white mb-4">AI Processor</h2>
                <GuidelineProcessor />
              </div>
            </div>
          </div>

          <div className="space-y-6" ref={reviewOutputRef}>
            {showReview && review.medicalHistory && (
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-2xl blur-xl"></div>
                <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-6 rounded-2xl">
                  <h2 className="text-2xl font-bold text-white mb-4">Generated Review</h2>
                  <ReviewOutput 
                    review={review} 
                    cptCodes={selectedCptCodes}
                  />
                </div>
              </div>
            )}

            {showReview && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={handleClearAll}
                  className="flex items-center px-6 py-3 bg-red-600 text-white rounded-xl hover:bg-red-700 transition-colors shadow-lg hover:shadow-xl"
                >
                  <Eraser className="h-5 w-5 mr-2" />
                  Clear All
                </button>
              </div>
            )}
          </div>
        </div>

        {clinicalNotes && (
          <AIChat
            clinicalNotes={clinicalNotes}
            guidelines={review.criteriaApplied}
            analysis={{
              summary: clinicalNotes,
              review: review.recommendation
            }}
            onDecisionUpdate={handleDecisionUpdate}
          />
        )}

        <ReviewHistory />
      </div>
    </div>
  );
};

export default Dashboard;