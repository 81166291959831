import { openAIClient } from './client';
import { openAISettings } from '../config/settings';
import { OpenAIError } from '../errors';
import { rateLimiter } from '../utils/rateLimiter';
import { truncateText } from '../utils/tokenizer';
import { handleOpenAIError } from '../utils/errorHandler';

export async function generateCompletion(
  text: string,
  systemPrompt: string,
  guidelines: string[] = []
): Promise<string> {
  if (!text?.trim()) {
    throw new OpenAIError('Text input is required', 'invalid_input');
  }

  let attempts = 0;
  const maxAttempts = openAISettings.retries;

  while (attempts < maxAttempts) {
    try {
      await rateLimiter.waitForAvailability();
      const truncatedText = truncateText(text);

      const response = await fetch(`${openAISettings.baseURL}/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${openAISettings.apiKey}`
        },
        body: JSON.stringify({
          model: openAISettings.model,
          messages: [
            {
              role: 'system',
              content: systemPrompt
            },
            {
              role: 'user',
              content: `${truncatedText}${guidelines.length > 0 ? `\n\nGuidelines to consider:\n${guidelines.join('\n')}` : ''}`
            }
          ],
          temperature: openAISettings.temperature,
          max_tokens: openAISettings.maxTokens,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new OpenAIError(
          errorData.error?.message || `API request failed with status ${response.status}`,
          'api_error',
          response.status
        );
      }

      const data = await response.json();
      const content = data.choices?.[0]?.message?.content;

      if (!content?.trim()) {
        throw new OpenAIError('No content received from API', 'empty_response');
      }

      return content.trim();

    } catch (error) {
      attempts++;

      if (error instanceof OpenAIError && error.code === 'invalid_input') {
        throw error;
      }

      if (attempts === maxAttempts) {
        return handleOpenAIError(error);
      }

      await new Promise(resolve => 
        setTimeout(resolve, openAISettings.timeout * attempts)
      );
    }
  }

  throw new OpenAIError('Failed to generate completion after multiple attempts', 'max_retries_exceeded');
}