import { generateCompletion } from './api/completion';
import { OpenAIError } from './errors';
import { AIResponse } from './types';
import { useAISettings } from '../../store/aiSettingsStore';
import { rateLimiter } from './utils/rateLimiter';
import { logError } from './utils/logging';

export class OpenAIService {
  private static readonly MAX_RETRIES = 3;
  private static readonly RETRY_DELAY = 1000;

  private static getPromptForType(type: string) {
    const { settings } = useAISettings.getState();
    
    if (!settings) {
      throw new OpenAIError(
        'AI settings not found. Please configure settings in the Admin Panel.',
        'missing_settings'
      );
    }

    switch (type) {
      case 'clinical_summary':
        if (!settings.clinicalSummaryPrompt?.trim()) {
          throw new OpenAIError('Clinical Summary prompt not configured', 'missing_prompt');
        }
        return `Analyze these clinical notes and provide:

1. A single sentence summary of the patient's main condition
2. A list of key diagnoses, each explained in one clear sentence
3. Any critical findings that affect treatment decisions

Format the response exactly as:

Summary:
[One sentence overview]

Diagnoses:
- [Diagnosis 1 explained in one sentence]
- [Diagnosis 2 explained in one sentence]
- [Diagnosis 3 explained in one sentence]

Key Findings:
- [Critical finding 1]
- [Critical finding 2]

IMPORTANT:
- Keep all explanations to single, clear sentences
- Use plain language
- Focus only on documented diagnoses
- Do not include ICD codes
- Do not make assumptions
- Do not add medical education or advice`;

      case 'medical_history':
        if (!settings.medicalHistoryPrompt?.trim()) {
          throw new OpenAIError('Medical History prompt not configured', 'missing_prompt');
        }
        return settings.medicalHistoryPrompt;

      case 'recommendation':
        if (!settings.recommendationPrompt?.trim()) {
          throw new OpenAIError('Recommendation prompt not configured', 'missing_prompt');
        }
        return settings.recommendationPrompt;

      case 'get_description':
        return 'Provide a clear, concise description of the CPT code. Return only the description without any additional commentary.';

      case 'date_of_service':
        return 'Find and extract the most recent service date from the clinical notes. Return ONLY the date in MM/DD/YYYY format. If no date is found, return today\'s date.';

      default:
        throw new OpenAIError(`Invalid prompt type: ${type}`, 'invalid_type');
    }
  }

  static async analyzeClinicalNotes(
    text: string,
    type: 'medical_history' | 'recommendation' | 'clinical_summary' | 'get_description' | 'date_of_service',
    guidelines: string[] = []
  ): Promise<AIResponse> {
    if (!text?.trim()) {
      throw new OpenAIError('Text input is required', 'invalid_input');
    }

    try {
      await rateLimiter.waitForAvailability();
      const prompt = this.getPromptForType(type);

      const content = await generateCompletion(text, prompt, guidelines);

      if (!content?.trim()) {
        throw new OpenAIError('No content received from API', 'empty_response');
      }

      // Process response based on type
      switch (type) {
        case 'clinical_summary': {
          const sections = this.parseSections(content);
          return {
            summary: sections.summary || content.trim(),
            keyFindings: sections.diagnoses || sections.keyFindings || [],
            recommendations: sections.recommendations || []
          };
        }

        case 'medical_history':
          return {
            summary: content.trim(),
            keyFindings: this.extractKeyFindings(content),
            recommendations: this.extractRecommendations(content)
          };

        case 'recommendation':
          return {
            summary: content.trim(),
            review: content.trim()
          };

        default:
          return {
            summary: content.trim()
          };
      }

    } catch (error) {
      logError('OpenAI service error:', {
        type,
        error,
        textLength: text.length,
        guidelinesCount: guidelines.length
      });

      if (error instanceof OpenAIError) {
        throw error;
      }

      throw new OpenAIError(
        'Failed to process request. Please try again.',
        'service_error'
      );
    }
  }

  private static parseSections(text: string): {
    summary?: string;
    diagnoses?: string[];
    keyFindings?: string[];
    recommendations?: string[];
  } {
    const sections: Record<string, string> = {};
    let currentSection = '';
    let content = '';

    // Split text into lines and process each line
    const lines = text.split('\n');
    for (const line of lines) {
      const trimmedLine = line.trim();
      
      // Check if line is a section header
      if (trimmedLine.endsWith(':')) {
        if (currentSection && content) {
          sections[currentSection.toLowerCase()] = content.trim();
        }
        currentSection = trimmedLine.slice(0, -1); // Remove colon
        content = '';
      } else if (trimmedLine && currentSection) {
        content += (content ? '\n' : '') + trimmedLine;
      }
    }

    // Add final section
    if (currentSection && content) {
      sections[currentSection.toLowerCase()] = content.trim();
    }

    // Extract sections
    const diagnoses = sections['diagnoses']?.split('\n')
      .map(line => line.replace(/^[-•]\s*/, '').trim())
      .filter(Boolean) || [];

    const keyFindings = sections['key findings']?.split('\n')
      .map(line => line.replace(/^[-•]\s*/, '').trim())
      .filter(Boolean) || [];

    const recommendations = sections['recommendations']?.split('\n')
      .map(line => line.replace(/^[-•]\s*/, '').trim())
      .filter(Boolean) || [];

    return {
      summary: sections['summary'],
      diagnoses,
      keyFindings,
      recommendations
    };
  }

  private static extractKeyFindings(text: string): string[] {
    const findings: string[] = [];
    const lines = text.split('\n');

    let inFindingsSection = false;
    for (const line of lines) {
      const trimmedLine = line.trim();
      
      if (trimmedLine.toLowerCase().includes('key findings:')) {
        inFindingsSection = true;
        continue;
      }

      if (inFindingsSection) {
        if (trimmedLine.toLowerCase().includes('recommendations:')) {
          break;
        }
        if (trimmedLine && !trimmedLine.match(/^[A-Z][a-z]+:/)) {
          findings.push(trimmedLine.replace(/^[-•]\s*/, ''));
        }
      }
    }

    return findings;
  }

  private static extractRecommendations(text: string): string[] {
    const recommendations: string[] = [];
    const lines = text.split('\n');

    let inRecommendationsSection = false;
    for (const line of lines) {
      const trimmedLine = line.trim();
      
      if (trimmedLine.toLowerCase().includes('recommendations:')) {
        inRecommendationsSection = true;
        continue;
      }

      if (inRecommendationsSection && trimmedLine) {
        recommendations.push(trimmedLine.replace(/^[-•]\s*/, ''));
      }
    }

    return recommendations;
  }
}