import React, { useState } from 'react';
import { Clock, Search, Trash2, X, Copy, Check, ChevronDown, ChevronUp } from 'lucide-react';
import { useReviewHistoryStore } from '../store/reviewHistoryStore';
import { format } from 'date-fns';

const ReviewHistory = () => {
  const { reviews, deleteReview } = useReviewHistoryStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [expandedReview, setExpandedReview] = useState<string | null>(null);
  const [copiedSection, setCopiedSection] = useState<string | null>(null);

  const filteredReviews = reviews.filter(review => 
    review.patientName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCopy = async (text: string, section: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedSection(section);
      setTimeout(() => setCopiedSection(null), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const renderSection = (title: string, content: string, id: string) => (
    <div className="mt-2 bg-white p-3 rounded-lg shadow-sm">
      <div className="flex justify-between items-center mb-1">
        <h4 className="text-sm font-medium text-gray-700">{title}</h4>
        <button
          onClick={() => handleCopy(content, `${id}-${title}`)}
          className="p-1 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
          title="Copy section"
        >
          {copiedSection === `${id}-${title}` ? (
            <Check className="h-4 w-4 text-green-500" />
          ) : (
            <Copy className="h-4 w-4" />
          )}
        </button>
      </div>
      <div className="text-sm text-gray-600 whitespace-pre-wrap">
        {content}
      </div>
    </div>
  );

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-4 py-3 flex items-center justify-between bg-black text-white hover:bg-gray-900 transition-colors"
        >
          <div className="flex items-center">
            <Clock className="h-5 w-5 mr-2" />
            <span className="font-medium">Review History</span>
          </div>
          {isOpen ? <X className="h-5 w-5" /> : <span>{reviews.length}</span>}
        </button>

        {isOpen && (
          <div className="p-4 max-h-[80vh] overflow-y-auto">
            <div className="mb-4">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                <input
                  type="text"
                  placeholder="Search by patient name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>

            <div className="space-y-4">
              {filteredReviews.map((item) => (
                <div
                  key={item.id}
                  className="bg-gray-50 rounded-lg p-4"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium text-gray-900">{item.patientName}</h3>
                      <p className="text-sm text-gray-500">
                        {format(new Date(item.timestamp), 'MMM d, yyyy h:mm a')}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => setExpandedReview(expandedReview === item.id ? null : item.id)}
                        className="p-1 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                      >
                        {expandedReview === item.id ? (
                          <ChevronUp className="h-4 w-4" />
                        ) : (
                          <ChevronDown className="h-4 w-4" />
                        )}
                      </button>
                      <button
                        onClick={() => deleteReview(item.id)}
                        className="p-1 text-red-600 hover:text-red-800 rounded-full hover:bg-red-50"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>

                  {expandedReview === item.id && (
                    <div className="mt-4 space-y-3">
                      {renderSection('Reason for Request', item.review.reasonForRequest, item.id)}
                      {renderSection('Date of Service', item.review.dateOfService, item.id)}
                      {renderSection('Medical History', item.review.medicalHistory, item.id)}
                      {renderSection('Criteria Applied', item.review.criteriaApplied.join('\n'), item.id)}
                      {renderSection('Recommendation', item.review.recommendation, item.id)}
                      {renderSection('Signature', item.review.signature, item.id)}
                      {renderSection('Additional Note', item.review.additionalNote, item.id)}
                    </div>
                  )}
                </div>
              ))}

              {filteredReviews.length === 0 && (
                <p className="text-center text-gray-500 py-4">
                  {searchTerm ? 'No reviews found' : 'No reviews yet'}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewHistory;