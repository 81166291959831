import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AudioState {
  isMuted: boolean;
  volume: number;
  speechRate: number;
  selectedVoice: string | null;
  toggleMute: () => void;
  setVolume: (volume: number) => void;
  setSpeechRate: (rate: number) => void;
  setSelectedVoice: (voice: string) => void;
}

export const useAudioStore = create<AudioState>()(
  persist(
    (set) => ({
      isMuted: false,
      volume: 1,
      speechRate: 1,
      selectedVoice: null,
      toggleMute: () => set((state) => ({ isMuted: !state.isMuted })),
      setVolume: (volume) => set({ volume: Math.max(0, Math.min(1, volume)) }),
      setSpeechRate: (rate) => set({ speechRate: Math.max(0.5, Math.min(2, rate)) }),
      setSelectedVoice: (voice) => set({ selectedVoice: voice })
    }),
    {
      name: 'audio-storage',
      partialize: (state) => ({
        isMuted: state.isMuted,
        volume: state.volume,
        speechRate: state.speechRate,
        selectedVoice: state.selectedVoice
      })
    }
  )
);