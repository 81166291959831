import React, { useState, useEffect } from 'react';
import { MessageSquare } from 'lucide-react';
import { OpenAIService } from '../services/openai/service';
import ChatHeader from './chat/ChatHeader';
import ChatMessages from './chat/ChatMessages';
import ChatInput from './chat/ChatInput';
import { Message } from '../types';
import { useData } from '../context/DataContext';

interface AIChatProps {
  clinicalNotes: string;
  guidelines: string[];
  analysis: {
    summary: string;
    review: string;
  };
  onDecisionUpdate?: (newDecision: string) => void;
}

const AIChat: React.FC<AIChatProps> = ({ 
  clinicalNotes, 
  guidelines, 
  analysis,
  onDecisionUpdate 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { criteria } = useData();

  // Add initial welcome message
  useEffect(() => {
    if (messages.length === 0) {
      setMessages([{
        role: 'ai',
        content: `Hello! I can help analyze the clinical notes and guidelines. I have access to all ${criteria.length} guidelines in the system. You can:

1. Ask why a specific guideline was marked as MEET or DOES NOT MEET
2. Question if specific evidence from the notes supports the decision
3. Challenge the recommendation if you think it's incorrect
4. Request clarification on how the guideline criteria were applied

How can I assist you today?`
      }]);
    }
  }, [criteria.length]);

  const handleSubmit = async (message: string) => {
    if (!message.trim() || isLoading) return;

    const userMessage: Message = { role: 'user', content: message };
    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);

    try {
      // Check if user is challenging a decision
      const challengeMatch = message.toLowerCase().match(/(?:why|how|should|disagree|incorrect|wrong|challenge|review|check)/);
      
      if (challengeMatch) {
        // Re-analyze with focus on evidence matching
        const prompt = `Please carefully review this challenge regarding the medical decision:

User Question: ${message}

Clinical Notes:
${clinicalNotes}

Current Guidelines:
${guidelines.join('\n')}

Current Review:
${analysis.review}

Instructions:
1. Carefully compare the clinical notes against each guideline requirement
2. Identify specific evidence that supports or contradicts the decision
3. Explain if the current decision is correct based on the evidence
4. If the decision should be different, explain why with specific evidence
5. Only reference information explicitly stated in the notes
6. Do not make assumptions or interpretations`;

        const response = await OpenAIService.analyzeClinicalNotes(
          prompt,
          'recommendation',
          guidelines
        );

        // If the response suggests a different decision, notify parent
        if (onDecisionUpdate && response.review) {
          onDecisionUpdate(response.review);
        }

        const aiMessage: Message = {
          role: 'ai',
          content: response.summary || 'I apologize, but I could not analyze the challenge. Please try rephrasing your question.'
        };
        setMessages(prev => [...prev, aiMessage]);
      } else {
        // Handle general questions about the notes/guidelines
        const response = await OpenAIService.analyzeClinicalNotes(
          `${clinicalNotes}\n\nCurrent Review:\n${analysis.review}\n\nUser Question:\n${message}`,
          'recommendation',
          guidelines
        );

        const aiMessage: Message = {
          role: 'ai',
          content: response.summary || 'I apologize, but I could not generate a response.'
        };
        setMessages(prev => [...prev, aiMessage]);
      }
    } catch (error) {
      console.error('Error getting AI response:', error);
      const errorMessage: Message = {
        role: 'ai',
        content: 'I apologize, but I encountered an error. Please try again.'
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 bg-black text-white rounded-full p-4 shadow-lg hover:bg-gray-900 transition-colors"
        title="Chat with AI"
      >
        <MessageSquare className="h-6 w-6" />
      </button>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 w-96 bg-white rounded-2xl shadow-xl border border-gray-200">
      <ChatHeader onClose={() => setIsOpen(false)} />
      <ChatMessages messages={messages} loading={isLoading} />
      <ChatInput onSubmit={handleSubmit} disabled={isLoading} />
    </div>
  );
};

export default AIChat;