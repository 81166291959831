import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Review } from '../types';

interface ReviewHistoryItem {
  id: string;
  patientName: string;
  timestamp: string;
  review: Review;
}

interface ReviewHistoryState {
  reviews: ReviewHistoryItem[];
  addReview: (patientName: string, review: Review) => void;
  deleteReview: (id: string) => void;
  clearHistory: () => void;
}

export const useReviewHistoryStore = create<ReviewHistoryState>()(
  persist(
    (set) => ({
      reviews: [],
      addReview: (patientName, review) => set((state) => {
        // Keep only the most recent 100 reviews
        const newReviews = [
          {
            id: crypto.randomUUID(),
            patientName,
            timestamp: new Date().toISOString(),
            review
          },
          ...state.reviews
        ].slice(0, 100);

        return { reviews: newReviews };
      }),
      deleteReview: (id) => set((state) => ({
        reviews: state.reviews.filter(review => review.id !== id)
      })),
      clearHistory: () => set({ reviews: [] })
    }),
    {
      name: 'review-history'
    }
  )
);