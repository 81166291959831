import { ADMIN_CREDENTIALS } from './adminCredentials';
import { AuthUser } from '../../types';
import { AuthError } from './errors';
import { subscriberService } from '../subscriber/SubscriberService';

export class AuthService {
  static async validateCredentials(username: string, password: string): Promise<AuthUser> {
    try {
      // Trim whitespace from credentials
      const trimmedUsername = username.trim();
      const trimmedPassword = password.trim();

      // Validate required fields
      if (!trimmedUsername || !trimmedPassword) {
        throw AuthError.missingCredentials();
      }

      // Case-sensitive exact match for admin credentials
      if (trimmedUsername === ADMIN_CREDENTIALS.username && 
          trimmedPassword === ADMIN_CREDENTIALS.password) {
        return {
          id: 'admin-1',
          email: ADMIN_CREDENTIALS.email,
          name: ADMIN_CREDENTIALS.name,
          active: true,
          isAdmin: true,
          credentials: ADMIN_CREDENTIALS.credentials
        };
      }

      // Try subscriber login
      const subscriber = await subscriberService.validateCredentials(trimmedUsername, trimmedPassword);
      if (subscriber) {
        return {
          id: subscriber.id,
          email: subscriber.email,
          name: subscriber.name,
          active: subscriber.status === 'active',
          isAdmin: false,
          credentials: ''
        };
      }

      // If no match found, throw invalid credentials error
      throw AuthError.invalidCredentials();

    } catch (error) {
      // Re-throw AuthErrors as-is
      if (error instanceof AuthError) {
        throw error;
      }

      // Log and wrap other errors
      console.error('Authentication error:', {
        username: username,
        error: error instanceof Error ? error.message : 'Unknown error'
      });

      throw new AuthError(
        error instanceof Error ? error.message : 'Authentication failed',
        'auth_error',
        error
      );
    }
  }
}