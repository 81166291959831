export const logInfo = (message: string, data?: any) => {
  console.log(`[OpenAI Service] ${message}`, data || '');
};

export const logWarning = (message: string, data?: any) => {
  console.warn(`[OpenAI Service] ${message}`, data || '');
};

export const logError = (message: string, error?: any) => {
  console.error(`[OpenAI Service] ${message}`, error || '');
};