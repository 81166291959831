import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface UMSystemDB extends DBSchema {
  auth_user: {
    key: string;
    value: {
      id: string;
      email: string;
      name: string;
      picture?: string;
      active: boolean;
      isAdmin: boolean;
      credentials?: string;
    };
    indexes: { 'by-email': string };
  };
  users: {
    key: string;
    value: {
      id: string;
      username: string;
      isAdmin: boolean;
      active: boolean;
    };
    indexes: { 'by-username': string };
  };
  cptCodes: {
    key: string;
    value: {
      id: string;
      code: string;
      lob: string;
      guideline: string;
    };
    indexes: { 'by-code': string; 'by-lob': string };
  };
  criteria: {
    key: string;
    value: {
      id: string;
      guideline: string;
      criteria: string;
      timestamp: Date;
    };
    indexes: { 'by-guideline': string };
  };
  procedures: {
    key: string;
    value: {
      id: string;
      code: string;
      description: string;
      procedureMd: string;
    };
    indexes: { 'by-code': string };
  };
  subscribers: {
    key: string;
    value: {
      id: string;
      name: string;
      username: string;
      password: string;
      email: string;
      plan: string;
      status: string;
      subscriptionEnd: string;
      lastPayment?: string;
      nextPayment?: string;
      freeAccess?: boolean;
    };
    indexes: { 
      'by-username': string;
      'by-email': string;
      'by-status': string;
    };
  };
  emailTemplates: {
    key: string;
    value: {
      id: string;
      type: string;
      subject: string;
      body: string;
      updatedAt: string;
    };
    indexes: { 'by-type': string };
  };
}

let db: IDBPDatabase<UMSystemDB>;

export const initDB = async () => {
  if (db) return db;

  // Increment version number to trigger upgrade
  db = await openDB<UMSystemDB>('um-system', 3, {
    upgrade(db, oldVersion, newVersion, transaction) {
      // Auth User store
      if (!db.objectStoreNames.contains('auth_user')) {
        const authStore = db.createObjectStore('auth_user', { keyPath: 'id' });
        authStore.createIndex('by-email', 'email', { unique: true });
      }

      // Users store
      if (!db.objectStoreNames.contains('users')) {
        const userStore = db.createObjectStore('users', { keyPath: 'id' });
        userStore.createIndex('by-username', 'username', { unique: true });
      }

      // CPT Codes store
      if (!db.objectStoreNames.contains('cptCodes')) {
        const cptStore = db.createObjectStore('cptCodes', { keyPath: 'id' });
        cptStore.createIndex('by-code', 'code');
        cptStore.createIndex('by-lob', 'lob');
      }

      // Criteria store
      if (!db.objectStoreNames.contains('criteria')) {
        const criteriaStore = db.createObjectStore('criteria', { keyPath: 'id' });
        criteriaStore.createIndex('by-guideline', 'guideline');
      }

      // Procedures store
      if (!db.objectStoreNames.contains('procedures')) {
        const procedureStore = db.createObjectStore('procedures', { keyPath: 'id' });
        procedureStore.createIndex('by-code', 'code');
      }

      // Subscribers store
      if (!db.objectStoreNames.contains('subscribers')) {
        const subscriberStore = db.createObjectStore('subscribers', { keyPath: 'id' });
        subscriberStore.createIndex('by-username', 'username', { unique: true });
        subscriberStore.createIndex('by-email', 'email', { unique: true });
        subscriberStore.createIndex('by-status', 'status');
      }

      // Email Templates store
      if (!db.objectStoreNames.contains('emailTemplates')) {
        const templateStore = db.createObjectStore('emailTemplates', { keyPath: 'id' });
        templateStore.createIndex('by-type', 'type', { unique: true });
      }

      // Initialize stores with empty arrays if needed
      const stores = ['auth_user', 'users', 'cptCodes', 'criteria', 'procedures', 'subscribers', 'emailTemplates'];
      stores.forEach(storeName => {
        if (db.objectStoreNames.contains(storeName)) {
          const store = transaction.objectStore(storeName);
          store.count().then(count => {
            if (count === 0) {
              store.add({ id: 'init', timestamp: new Date() });
            }
          });
        }
      });
    },
  });

  return db;
};

export const getDB = async () => {
  if (!db) {
    await initDB();
  }
  return db;
};