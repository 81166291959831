import React, { useState, useRef, useEffect } from 'react';
import { Clock, Copy, Check, RefreshCw } from 'lucide-react';
import { Review } from '../types';
import { useData } from '../context/DataContext';
import { useAudioStore } from '../store/audioStore';
import AutoReadSummary from './AutoReadSummary';

interface ReviewOutputProps {
  review: Review;
  cptCodes?: string[];
  onRegenerate?: () => void;
}

const ReviewOutput: React.FC<ReviewOutputProps> = ({ review, cptCodes = [], onRegenerate }) => {
  const [copiedSection, setCopiedSection] = useState<string | null>(null);
  const { procedures } = useData();
  const { isMuted } = useAudioStore();
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Get descriptions without CPT codes
  const descriptions = cptCodes
    ?.map(code => {
      const procedure = procedures.find(p => p.code === code);
      return procedure?.description;
    })
    .filter(Boolean);

  const sections = [
    { 
      id: 'reason',
      title: 'Reason for Request', 
      content: descriptions?.length 
        ? descriptions.join('. ') + '.'
        : 'No CPT code descriptions available.'
    },
    { 
      id: 'date',
      title: 'Date of Service', 
      content: review.dateOfService ? new Date(review.dateOfService).toLocaleDateString() : ''
    },
    { 
      id: 'history',
      title: 'Medical History', 
      content: review.medicalHistory,
      hasAudio: true
    },
    { 
      id: 'criteria',
      title: 'Criteria Applied',
      content: 'Followed hierarchy of LOB:\n' + review.criteriaApplied.join('\n')
    },
    { 
      id: 'recommendation',
      title: 'Recommendation', 
      content: review.recommendation,
      showRegenerate: true,
      hasAudio: true,
      autoPlay: true // Auto-play the recommendation section
    },
    { 
      id: 'signature',
      title: 'Signature', 
      content: review.signature 
    },
    { 
      id: 'note',
      title: 'Additional Note', 
      content: review.additionalNote 
    }
  ];

  const copyToClipboard = async (text: string, section: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedSection(section);
      setTimeout(() => {
        setCopiedSection(null);
        // Find and scroll to next section's copy button
        const currentIndex = sections.findIndex(s => s.id === section);
        if (currentIndex < sections.length - 1) {
          const nextSection = sections[currentIndex + 1];
          sectionRefs.current[nextSection.id]?.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center'
          });
        }
      }, 1000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className="space-y-6">
      {sections.map(section => (
        <div 
          key={section.id}
          ref={el => sectionRefs.current[section.id] = el}
          data-section={section.id}
          className="bg-white/10 rounded-lg p-6 border border-white/20"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-white">{section.title}</h3>
            <div className="flex items-center space-x-2">
              {section.showRegenerate && onRegenerate && (
                <button
                  onClick={onRegenerate}
                  className="p-2 text-white/60 hover:text-white rounded-full hover:bg-white/10 transition-colors"
                  title="Regenerate recommendation"
                >
                  <RefreshCw className="h-4 w-4" />
                </button>
              )}
              <button
                onClick={() => copyToClipboard(section.content, section.id)}
                className="p-2 text-white/60 hover:text-white rounded-full hover:bg-white/10 transition-colors"
                title="Copy section"
              >
                {copiedSection === section.id ? (
                  <Check className="h-4 w-4 text-green-400" />
                ) : (
                  <Copy className="h-4 w-4" />
                )}
              </button>
            </div>
          </div>
          <div className="text-white/90 whitespace-pre-wrap">
            {section.content}
          </div>
          {section.hasAudio && (
            <div className="mt-4">
              <AutoReadSummary 
                text={section.content} 
                autoPlay={section.autoPlay || false}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ReviewOutput;