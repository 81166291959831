export class GoogleAIService {
  private static readonly API_KEY = 'AIzaSyCVS1LjYbZTdXXuQsxqBUDYDWogGr2Kwrs';
  private static readonly SPEECH_API_ENDPOINT = 'https://speech.googleapis.com/v1p1beta1/speech:recognize';
  private static readonly VISION_API_ENDPOINT = 'https://vision.googleapis.com/v1/images:annotate';

  static async transcribeSpeech(audioData: Blob): Promise<string> {
    try {
      // Convert audio blob to base64
      const reader = new FileReader();
      const audioBase64 = await new Promise<string>((resolve) => {
        reader.onload = () => {
          const base64 = (reader.result as string).split(',')[1];
          resolve(base64);
        };
        reader.readAsDataURL(audioData);
      });

      const response = await fetch(`${this.SPEECH_API_ENDPOINT}?key=${this.API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          config: {
            encoding: 'WEBM_OPUS',
            sampleRateHertz: 48000,
            languageCode: 'en-US',
            model: 'medical_conversation',
            useEnhanced: true,
            enableAutomaticPunctuation: true
          },
          audio: {
            content: audioBase64
          }
        })
      });

      if (!response.ok) {
        throw new Error('Speech recognition failed');
      }

      const data = await response.json();
      return data.results?.[0]?.alternatives?.[0]?.transcript || '';
    } catch (error) {
      console.error('Speech recognition error:', error);
      throw error;
    }
  }

  static async performOCR(imageData: Blob): Promise<string> {
    try {
      // Convert image blob to base64
      const reader = new FileReader();
      const imageBase64 = await new Promise<string>((resolve) => {
        reader.onload = () => {
          const base64 = (reader.result as string).split(',')[1];
          resolve(base64);
        };
        reader.readAsDataURL(imageData);
      });

      const response = await fetch(`${this.VISION_API_ENDPOINT}?key=${this.API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          requests: [{
            image: {
              content: imageBase64
            },
            features: [{
              type: 'DOCUMENT_TEXT_DETECTION',
              model: 'builtin/latest'
            }],
            imageContext: {
              languageHints: ['en']
            }
          }]
        })
      });

      if (!response.ok) {
        throw new Error('OCR processing failed');
      }

      const data = await response.json();
      return data.responses?.[0]?.fullTextAnnotation?.text || '';
    } catch (error) {
      console.error('OCR processing error:', error);
      throw error;
    }
  }
}