import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { LocalStorageService } from '../services/LocalStorageService';
import { AuthService } from '../services/auth/authService';
import { SessionManager } from '../services/auth/sessionManager';
import { AuthError } from '../services/auth/errors';
import { AuthUser } from '../types';

interface AuthContextType {
  user: AuthUser | null;
  loading: boolean;
  error: string | null;
  login: (credentials: { username: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
  checkAuthStatus: () => Promise<void>;
}

const AUTH_KEY = 'auth_user';

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  error: null,
  login: async () => {},
  logout: async () => {},
  checkAuthStatus: async () => {}
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const checkAuthStatus = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      if (!SessionManager.hasActiveSession()) {
        setUser(null);
        return;
      }

      const storedUser = await LocalStorageService.getData(AUTH_KEY);
      
      if (storedUser?.length) {
        const currentUser = storedUser[0];
        if (SessionManager.validateSession(currentUser.isAdmin)) {
          setUser(currentUser);
        } else {
          setUser(null);
          setError('Session expired');
        }
      } else {
        setUser(null);
      }
    } catch (err) {
      console.error('Auth status check error:', err);
      setUser(null);
      setError('Failed to check auth status');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = async (credentials: { username: string; password: string }) => {
    try {
      setLoading(true);
      setError(null);

      if (!credentials.username || !credentials.password) {
        throw AuthError.missingCredentials();
      }

      const authenticatedUser = await AuthService.validateCredentials(
        credentials.username,
        credentials.password
      );

      if (authenticatedUser) {
        await LocalStorageService.setData(AUTH_KEY, [authenticatedUser]);
        SessionManager.createSession(authenticatedUser.isAdmin);
        setUser(authenticatedUser);
      } else {
        throw AuthError.invalidCredentials();
      }
    } catch (err) {
      const message = err instanceof AuthError ? err.message : 'Login failed';
      console.error('Login error:', { message, error: err });
      setError(message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      setLoading(true);
      setError(null);
      await LocalStorageService.clearData(AUTH_KEY);
      SessionManager.clearSession();
      setUser(null);
    } catch (err) {
      console.error('Logout error:', err);
      setUser(null);
      SessionManager.clearSession();
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      loading, 
      error, 
      login, 
      logout,
      checkAuthStatus 
    }}>
      {children}
    </AuthContext.Provider>
  );
};