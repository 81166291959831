import React, { useState, useCallback } from 'react';
import { useFormStore } from '../store/formStore';
import { useData } from '../context/DataContext';
import { useReviewGeneration } from '../hooks/useReviewGeneration';
import { Review } from '../types';
import CptCodeInput from './cpt/CptCodeInput';
import GuidelineDisplay from './GuidelineDisplay';
import MedicalHistoryInput from './MedicalHistoryInput';
import GuidelineSuggestions from './suggestions/GuidelineSuggestions';
import DiagnosisSummary from './suggestions/DiagnosisSummary';
import { Eraser, Loader2 } from 'lucide-react';

interface ReviewFormProps {
  onReviewGenerate: (review: Review, cptCodes: string[]) => void;
  onClinicalNotesChange?: (notes: string) => void;
}

const ReviewForm: React.FC<ReviewFormProps> = ({ 
  onReviewGenerate,
  onClinicalNotesChange 
}) => {
  const formStore = useFormStore();
  const { stopTimer } = useFormStore();
  const { generateReview, error: reviewError, isGenerating } = useReviewGeneration();
  const { procedures = [] } = useData();
  
  const [clinicalNotes, setClinicalNotes] = useState(formStore.clinicalNotes || '');
  const [aiFocus, setAIFocus] = useState(formStore.aiFocus || '');
  const [selectedCptCodes, setSelectedCptCodes] = useState<string[]>(formStore.selectedCptCodes || []);
  const [selectedGuidelines, setSelectedGuidelines] = useState<string[]>(formStore.selectedGuidelines || []);
  const [selectedLOB, setSelectedLOB] = useState<string>(formStore.selectedLOB || 'Medicare');
  const [error, setError] = useState<string | null>(null);

  const handleCodesChange = useCallback((codes: string[]) => {
    setSelectedCptCodes(codes);
    formStore.setFormData({ selectedCptCodes: codes });
  }, [formStore]);

  const handleClinicalNotesChange = useCallback((value: string) => {
    setClinicalNotes(value);
    formStore.setFormData({ clinicalNotes: value });
    onClinicalNotesChange?.(value);
  }, [formStore, onClinicalNotesChange]);

  const handleAIFocusChange = useCallback((value: string) => {
    setAIFocus(value);
    formStore.setFormData({ aiFocus: value });
  }, [formStore]);

  const handleGuidelineSelect = useCallback((guidelines: string[]) => {
    setSelectedGuidelines(guidelines);
    formStore.setFormData({ selectedGuidelines: guidelines });
  }, [formStore]);

  const handleClearCptCodes = () => {
    setSelectedCptCodes([]);
    formStore.setFormData({ selectedCptCodes: [] });
  };

  const handleClearGuidelines = () => {
    setSelectedGuidelines([]);
    formStore.setFormData({ selectedGuidelines: [] });
  };

  const handleReviewGeneration = async () => {
    if (!clinicalNotes.trim()) {
      setError('Clinical notes are required');
      return;
    }

    if (!selectedGuidelines.length) {
      setError('Please select at least one guideline');
      return;
    }

    try {
      setError(null);

      // Get CPT code descriptions
      const cptDescriptions = selectedCptCodes.map(code => {
        const procedure = procedures.find(p => p.code === code);
        return procedure ? { code, description: procedure.description } : null;
      }).filter(Boolean);

      console.log('Generating review with:', {
        notesLength: clinicalNotes.length,
        cptCodes: cptDescriptions.length,
        guidelines: selectedGuidelines.length
      });

      // Stop the timer before generating review
      stopTimer();

      const generatedReview = await generateReview(
        clinicalNotes,
        cptDescriptions,
        selectedGuidelines,
        aiFocus
      );
      
      if (generatedReview) {
        console.log('Review generated successfully:', generatedReview);
        onReviewGenerate(generatedReview, selectedCptCodes);

        // Scroll to reason for request section
        const reasonSection = document.querySelector('[data-section="reason"]');
        if (reasonSection) {
          reasonSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else {
        throw new Error('No review was generated');
      }
    } catch (error) {
      console.error('Error generating review:', error);
      setError(error instanceof Error ? error.message : 'Failed to generate review');
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-white mb-2">
          Line of Business
        </label>
        <select
          value={selectedLOB}
          onChange={(e) => setSelectedLOB(e.target.value)}
          className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white focus:ring-2 focus:ring-indigo-500 [&>*]:text-black"
        >
          <optgroup label="Medicare Plans">
            <option value="Medicare">Medicare</option>
            <option value="Aetna Medicare">Aetna Medicare</option>
            <option value="BCBS Medicare">BCBS Medicare</option>
            <option value="Humana Medicare">Humana Medicare</option>
            <option value="UHC Medicare">UHC Medicare</option>
          </optgroup>

          <optgroup label="Medicaid Plans">
            <option value="Medicaid">Medicaid</option>
            <option value="Aetna Medicaid">Aetna Medicaid</option>
            <option value="BCBS Medicaid">BCBS Medicaid</option>
            <option value="Humana Medicaid">Humana Medicaid</option>
            <option value="Medi-Cal">Medi-Cal</option>
            <option value="UHC Medicaid">UHC Medicaid</option>
          </optgroup>

          <optgroup label="Commercial Plans">
            <option value="Commercial">Commercial</option>
            <option value="Aetna Commercial">Aetna Commercial</option>
            <option value="BCBS Commercial">BCBS Commercial</option>
            <option value="Humana Commercial">Humana Commercial</option>
            <option value="Medicare Commercial">Medicare Commercial</option>
            <option value="UHC Commercial">UHC Commercial</option>
          </optgroup>

          <optgroup label="Other Plans">
            <option value="Aetna">Aetna</option>
            <option value="Aetna Better Health">Aetna Better Health</option>
            <option value="Amerigroup">Amerigroup</option>
            <option value="Anthem">Anthem</option>
            <option value="BCBS">BCBS</option>
            <option value="Cigna">Cigna</option>
            <option value="Humana">Humana</option>
            <option value="Molina">Molina</option>
            <option value="UHC">UHC</option>
            <option value="United Healthcare">United Healthcare</option>
          </optgroup>
        </select>
      </div>

      <MedicalHistoryInput
        clinicalNotes={clinicalNotes}
        aiFocus={aiFocus}
        onClinicalNotesChange={handleClinicalNotesChange}
        onAIFocusChange={handleAIFocusChange}
      />

      {clinicalNotes && <DiagnosisSummary clinicalNotes={clinicalNotes} />}

      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="text-sm font-medium text-white">CPT Codes</label>
          {selectedCptCodes.length > 0 && (
            <button
              onClick={handleClearCptCodes}
              className="flex items-center px-3 py-1.5 text-sm text-white/80 hover:text-white bg-red-500/20 hover:bg-red-500/30 rounded-lg transition-colors"
            >
              <Eraser className="h-4 w-4 mr-2" />
              Clear CPT Codes
            </button>
          )}
        </div>
        <CptCodeInput
          selectedCptCodes={selectedCptCodes}
          selectedLOB={selectedLOB}
          onCodesChange={handleCodesChange}
        />
      </div>

      {selectedLOB && (
        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="text-sm font-medium text-white">Guidelines</label>
            {selectedGuidelines.length > 0 && (
              <button
                onClick={handleClearGuidelines}
                className="flex items-center px-3 py-1.5 text-sm text-white/80 hover:text-white bg-red-500/20 hover:bg-red-500/30 rounded-lg transition-colors"
              >
                <Eraser className="h-4 w-4 mr-2" />
                Clear Guidelines
              </button>
            )}
          </div>
          <GuidelineDisplay
            cptCodes={selectedCptCodes}
            selectedLOB={selectedLOB}
            selectedGuidelines={selectedGuidelines}
            onGuidelineSelect={handleGuidelineSelect}
          />

          {clinicalNotes && (
            <GuidelineSuggestions
              clinicalNotes={clinicalNotes}
              selectedLOB={selectedLOB}
              onSelectGuideline={(guideline) => {
                setSelectedGuidelines(prev => {
                  const newGuidelines = prev.includes(guideline) 
                    ? prev 
                    : [...prev, guideline];
                  return newGuidelines;
                });
              }}
            />
          )}
        </div>
      )}

      {(error || reviewError) && (
        <div className="bg-red-500/20 border border-red-300/20 rounded-md p-4">
          <p className="text-sm text-red-300">{error || reviewError}</p>
        </div>
      )}

      <div className="flex justify-end">
        <button
          onClick={handleReviewGeneration}
          disabled={isGenerating || !clinicalNotes.trim() || selectedGuidelines.length === 0}
          className="px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded-xl hover:from-blue-600 hover:to-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 shadow-lg hover:shadow-xl flex items-center space-x-2"
        >
          {isGenerating ? (
            <>
              <Loader2 className="h-5 w-5 animate-spin" />
              <span>Generating...</span>
            </>
          ) : (
            <span>Generate Review</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default ReviewForm;