export function extractPatientName(text: string): string | null {
  if (!text?.trim()) return null;

  // Look for common patient name patterns
  const patterns = [
    // Name field pattern
    /(?:Patient Name|Name|Patient):\s*([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})/,
    // Title with name pattern
    /(?:Mr\.|Mrs\.|Ms\.|Dr\.)\s+([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})/,
    // Name with action pattern
    /([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})\s+(?:presents|reports|states|denies)/,
    // Name at start of line pattern
    /^([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})/m,
    // Name after newline pattern
    /\n([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})/
  ];

  for (const pattern of patterns) {
    const match = text.match(pattern);
    if (match?.[1]) {
      // Split name into parts and reorder
      const nameParts = match[1].trim().split(/\s+/);
      if (nameParts.length >= 2) {
        const lastName = nameParts[nameParts.length - 1];
        const firstName = nameParts.slice(0, -1).join(' ');
        return `${lastName}, ${firstName}`;
      }
      return match[1].trim();
    }
  }

  // Look for capitalized words at the start of the text
  const firstLine = text.split('\n')[0];
  const nameMatch = firstLine.match(/^([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})/);
  if (nameMatch?.[1]) {
    const nameParts = nameMatch[1].trim().split(/\s+/);
    if (nameParts.length >= 2) {
      const lastName = nameParts[nameParts.length - 1];
      const firstName = nameParts.slice(0, -1).join(' ');
      return `${lastName}, ${firstName}`;
    }
    return nameMatch[1].trim();
  }

  return null;
}