import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { aiResponseCache } from '../services/AIResponseCache';

interface FormState {
  clinicalNotes: string;
  ocrText: string;
  selectedCptCodes: string[];
  selectedGuidelines: string[];
  selectedLOB: string;
  timerActive: boolean;
  timerStart: number | null;
  hasStartedFirstReview: boolean;
  aiFocus: string;
  review: {
    reasonForRequest: string;
    dateOfService: string;
    medicalHistory: string;
    criteriaApplied: string[];
    recommendation: string;
    signature: string;
    additionalNote: string;
  };
  patientName: string;
  showReview: boolean;
  error: string | null;
}

interface FormActions {
  setFormData: (data: Partial<FormState>) => void;
  clearForm: () => void;
  startTimer: () => void;
  stopTimer: () => void;
}

const initialState: FormState = {
  clinicalNotes: '',
  ocrText: '',
  selectedCptCodes: [],
  selectedGuidelines: [],
  selectedLOB: '',
  timerActive: false,
  timerStart: null,
  hasStartedFirstReview: false,
  aiFocus: '',
  review: {
    reasonForRequest: '',
    dateOfService: new Date().toISOString(),
    medicalHistory: '',
    criteriaApplied: [],
    recommendation: '',
    signature: '',
    additionalNote: 'N/A, current attached clinical notes reviewed.'
  },
  patientName: '',
  showReview: false,
  error: null
};

export const useFormStore = create<FormState & FormActions>()(
  persist(
    (set) => ({
      ...initialState,
      setFormData: (data) => set((state) => ({ ...state, ...data })),
      clearForm: () => {
        // Clear AI response cache
        aiResponseCache.clearCache();
        // Reset form state to initial values while preserving timer state
        set((state) => ({
          ...initialState,
          timerActive: state.timerActive,
          timerStart: state.timerStart,
          review: {
            reasonForRequest: '',
            dateOfService: new Date().toISOString(),
            medicalHistory: '',
            criteriaApplied: [],
            recommendation: '',
            signature: state.review.signature, // Preserve signature
            additionalNote: 'N/A, current attached clinical notes reviewed.'
          }
        }));
      },
      startTimer: () => set({ timerActive: true, timerStart: Date.now() }),
      stopTimer: () => set({ timerActive: false, timerStart: null })
    }),
    {
      name: 'form-storage',
      partialize: (state) => ({
        clinicalNotes: state.clinicalNotes,
        selectedCptCodes: state.selectedCptCodes,
        selectedGuidelines: state.selectedGuidelines,
        selectedLOB: state.selectedLOB,
        aiFocus: state.aiFocus,
        review: state.review
      })
    }
  )
);