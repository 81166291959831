import { LocalStorageService } from '../storage/LocalStorageService';
import { hashPassword, verifyPassword } from '../../utils/crypto';

export interface Subscriber {
  id: string;
  name: string;
  username: string;
  password: string;
  email: string;
  plan: 'monthly' | 'annual' | 'free';
  status: 'active' | 'inactive';
  subscriptionEnd: string;
  lastPayment?: string;
  nextPayment?: string;
  freeAccess?: boolean;
}

class SubscriberService {
  private static instance: SubscriberService;
  private subscribers: Subscriber[] = [];
  private initialized = false;

  private constructor() {
    this.initialize();
  }

  static getInstance(): SubscriberService {
    if (!SubscriberService.instance) {
      SubscriberService.instance = new SubscriberService();
    }
    return SubscriberService.instance;
  }

  private async initialize() {
    if (this.initialized) return;
    
    try {
      const data = await LocalStorageService.getData('subscribers');
      this.subscribers = data as Subscriber[];
      this.initialized = true;
      console.log('Subscriber service initialized with', this.subscribers.length, 'subscribers');
    } catch (error) {
      console.error('Error loading subscribers:', error);
      this.subscribers = [];
      this.initialized = true;
    }
  }

  private async saveSubscribers() {
    try {
      await LocalStorageService.setData('subscribers', this.subscribers);
      console.log('Subscribers saved successfully');
    } catch (error) {
      console.error('Error saving subscribers:', error);
      throw error;
    }
  }

  async addSubscriber(subscriber: Omit<Subscriber, 'id' | 'status' | 'subscriptionEnd'>): Promise<Subscriber> {
    await this.initialize();

    // Case-insensitive username check
    if (this.subscribers.some(s => s.username.toLowerCase() === subscriber.username.toLowerCase())) {
      throw new Error('Username already exists');
    }

    // Case-insensitive email check
    if (this.subscribers.some(s => s.email.toLowerCase() === subscriber.email.toLowerCase())) {
      throw new Error('Email already exists');
    }

    const hashedPassword = await hashPassword(subscriber.password);
    
    const newSubscriber: Subscriber = {
      id: crypto.randomUUID(),
      ...subscriber,
      password: hashedPassword,
      status: 'active',
      subscriptionEnd: subscriber.plan === 'free' 
        ? new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString() // 1 year for free accounts
        : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(), // 30 days for paid accounts
      nextPayment: subscriber.plan === 'free' 
        ? undefined 
        : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
      freeAccess: subscriber.plan === 'free'
    };

    this.subscribers.push(newSubscriber);
    await this.saveSubscribers();
    console.log('New subscriber added:', newSubscriber.username);
    return newSubscriber;
  }

  async validateCredentials(username: string, password: string): Promise<Subscriber | null> {
    await this.initialize();
    console.log('Validating credentials for:', username);
    
    // Case-insensitive username search
    const subscriber = this.subscribers.find(s => 
      s.username.toLowerCase() === username.toLowerCase()
    );

    if (!subscriber) {
      console.log('Subscriber not found:', username);
      return null;
    }

    if (subscriber.status !== 'active') {
      console.log('Subscriber account inactive:', username);
      return null;
    }

    const isValid = await verifyPassword(password, subscriber.password);
    if (!isValid) {
      console.log('Invalid password for subscriber:', username);
      return null;
    }

    // Check subscription status for non-free accounts
    if (!subscriber.freeAccess) {
      const subscriptionEnd = new Date(subscriber.subscriptionEnd);
      if (subscriptionEnd < new Date()) {
        console.log('Subscription expired for:', username);
        await this.updateSubscriber(subscriber.id, { status: 'inactive' });
        return null;
      }
    }

    console.log('Subscriber validated successfully:', username);
    return subscriber;
  }

  async updateSubscriber(id: string, updates: Partial<Subscriber>): Promise<Subscriber | null> {
    await this.initialize();

    const index = this.subscribers.findIndex(s => s.id === id);
    if (index === -1) return null;

    // Check for duplicate username/email
    if (updates.username && 
        this.subscribers.some(s => 
          s.id !== id && 
          s.username.toLowerCase() === updates.username?.toLowerCase()
        )) {
      throw new Error('Username already exists');
    }

    if (updates.email && 
        this.subscribers.some(s => 
          s.id !== id && 
          s.email.toLowerCase() === updates.email?.toLowerCase()
        )) {
      throw new Error('Email already exists');
    }

    if (updates.password) {
      updates.password = await hashPassword(updates.password);
    }

    this.subscribers[index] = { ...this.subscribers[index], ...updates };
    await this.saveSubscribers();
    console.log('Subscriber updated:', this.subscribers[index].username);
    return this.subscribers[index];
  }

  async deleteSubscriber(id: string): Promise<boolean> {
    await this.initialize();

    const index = this.subscribers.findIndex(s => s.id === id);
    if (index === -1) return false;

    this.subscribers.splice(index, 1);
    await this.saveSubscribers();
    console.log('Subscriber deleted:', id);
    return true;
  }

  getSubscribers(): Subscriber[] {
    return [...this.subscribers];
  }

  getActiveSubscribers(): Subscriber[] {
    return this.subscribers.filter(s => s.status === 'active');
  }

  getCanceledSubscribers(): Subscriber[] {
    return this.subscribers.filter(s => s.status === 'inactive');
  }

  getAtRiskSubscribers(): Subscriber[] {
    const now = new Date();
    const threeDaysFromNow = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000);
    
    return this.subscribers.filter(s => {
      if (s.freeAccess) return false;
      const subscriptionEnd = new Date(s.subscriptionEnd);
      return s.status === 'active' && subscriptionEnd <= threeDaysFromNow;
    });
  }

  getFreeAccessSubscribers(): Subscriber[] {
    return this.subscribers.filter(s => s.freeAccess);
  }
}

export const subscriberService = SubscriberService.getInstance();