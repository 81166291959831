export class OpenAIError extends Error {
  constructor(
    message: string,
    public code: string = 'unknown_error',
    public status?: number,
    public originalError?: any
  ) {
    super(message);
    this.name = 'OpenAIError';

    // Ensure proper prototype chain
    Object.setPrototypeOf(this, OpenAIError.prototype);

    // Capture stack trace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, OpenAIError);
    }
  }

  static fromError(error: unknown): OpenAIError {
    if (error instanceof OpenAIError) {
      return error;
    }

    if (error instanceof Error) {
      return new OpenAIError(
        error.message,
        'unknown_error',
        undefined,
        error
      );
    }

    return new OpenAIError(
      'An unknown error occurred',
      'unknown_error',
      undefined,
      error
    );
  }
}