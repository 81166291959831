import React from 'react';
import { AlertCircle } from 'lucide-react';
import type { SummaryDisplayProps } from './types';
import AutoReadSummary from '../AutoReadSummary';

export const SummaryDisplay: React.FC<SummaryDisplayProps> = ({
  summary,
  isLoading,
  error,
  autoPlay = false
}) => {
  if (error) {
    return (
      <div className="mt-2 p-3 bg-red-500/20 rounded-md flex items-center">
        <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
        <span className="text-sm text-red-300">{error}</span>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="mt-2 p-4 bg-white/5 rounded-md animate-pulse">
        <div className="h-4 bg-white/10 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-white/10 rounded w-1/2"></div>
      </div>
    );
  }

  if (!summary) return null;

  return (
    <div className="mt-2 p-4 bg-white/10 rounded-md">
      <div className="mb-4">
        <AutoReadSummary text={summary} autoPlay={autoPlay} />
      </div>
      <p className="text-sm text-white/90">{summary}</p>
    </div>
  );
}