import React, { useEffect, useRef } from 'react';
import { SpeechButton } from './speech/SpeechButton';
import { useSpeechSynthesis } from './speech/useSpeechSynthesis';

interface AutoReadSummaryProps {
  text: string;
  autoPlay?: boolean;
}

const AutoReadSummary: React.FC<AutoReadSummaryProps> = ({ text, autoPlay = false }) => {
  const { isPlaying, isMuted, startSpeaking, stopSpeaking } = useSpeechSynthesis(text, false); // Force autoPlay to false
  const hasAutoPlayed = useRef(false);

  useEffect(() => {
    // Only auto-play once and only if autoPlay is true and not muted
    if (autoPlay && !isMuted && !hasAutoPlayed.current) {
      startSpeaking();
      hasAutoPlayed.current = true;
    }
  }, [autoPlay, isMuted, startSpeaking]);

  // Reset the auto-play flag when text changes
  useEffect(() => {
    hasAutoPlayed.current = false;
  }, [text]);

  return (
    <div className="flex items-center space-x-2">
      <SpeechButton
        isPlaying={isPlaying}
        isMuted={isMuted}
        onStart={startSpeaking}
        onStop={stopSpeaking}
      />
    </div>
  );
};

export default AutoReadSummary;