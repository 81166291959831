import React, { useState } from 'react';
import { Eraser, Plus, Minus } from 'lucide-react';
import { useFormStore } from '../store/formStore';
import { useReviewStore } from '../store/reviewStore';

const ReviewCounter = () => {
  const { clearForm, stopTimer } = useFormStore();
  const { totalReviews, resetReviews } = useReviewStore();
  const [isEditing, setIsEditing] = useState(false);

  const handleReset = () => {
    stopTimer();
    clearForm();
    resetReviews();
  };

  const handleIncrement = () => {
    useReviewStore.setState(state => ({
      totalReviews: state.totalReviews + 1
    }));
  };

  const handleDecrement = () => {
    useReviewStore.setState(state => ({
      totalReviews: Math.max(0, state.totalReviews - 1)
    }));
  };

  return (
    <div className="flex items-center space-x-4 bg-white rounded-lg shadow px-4 py-2">
      <div className="flex items-center space-x-2">
        <span className="text-sm font-medium text-gray-700">Total Reviews:</span>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleDecrement}
            className="p-1 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded transition-colors"
            title="Decrease count"
          >
            <Minus className="h-4 w-4" />
          </button>
          <span className="text-lg font-bold text-indigo-600 min-w-[2ch] text-center">
            {totalReviews}
          </span>
          <button
            onClick={handleIncrement}
            className="p-1 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded transition-colors"
            title="Increase count"
          >
            <Plus className="h-4 w-4" />
          </button>
        </div>
      </div>
      <button
        onClick={handleReset}
        className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-md flex items-center space-x-1"
        title="Reset Counter"
      >
        <Eraser className="h-4 w-4 mr-1" />
        <span>Reset</span>
      </button>
    </div>
  );
};

export default ReviewCounter;