import React, { useState, useEffect } from 'react';
import { Lightbulb, Loader2, AlertCircle } from 'lucide-react';
import { useData } from '../../context/DataContext';
import { OpenAIService } from '../../services/openai/service';

interface GuidelineSuggestionsProps {
  clinicalNotes: string;
  selectedLOB: string;
  onSelectGuideline: (guideline: string) => void;
}

const GuidelineSuggestions: React.FC<GuidelineSuggestionsProps> = ({
  clinicalNotes,
  selectedLOB,
  onSelectGuideline
}) => {
  const { criteria } = useData();
  const [suggestions, setSuggestions] = useState<Array<{
    guideline: string;
    relevance: string;
    confidence: number;
  }>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const analyzeClinicalNotes = async () => {
      if (!clinicalNotes?.trim() || !selectedLOB || !criteria.length) return;

      setLoading(true);
      setError(null);
      try {
        // Create a prompt that asks for specific guideline matches
        const prompt = `Analyze these clinical notes for a ${selectedLOB} patient and identify the 3 most relevant guidelines from the list below. For each suggested guideline, explain in one sentence why it's relevant to this case.

Clinical Notes:
${clinicalNotes}

Available Guidelines:
${criteria.map(c => `- ${c.guideline}`).join('\n')}

Format your response exactly as:
1. [Guideline Title]
Relevance: [One sentence explanation]
Confidence: [Number between 0-100]

2. [Guideline Title]
Relevance: [One sentence explanation]
Confidence: [Number between 0-100]

3. [Guideline Title]
Relevance: [One sentence explanation]
Confidence: [Number between 0-100]

IMPORTANT:
- Only suggest guidelines that clearly match the patient's condition
- Base suggestions on explicit evidence in the notes
- Provide specific reasons why each guideline applies
- Order by confidence level (highest first)
- Only include guidelines from the provided list`;

        const response = await OpenAIService.analyzeClinicalNotes(
          prompt,
          'recommendation',
          []
        );

        if (response.summary) {
          // Parse the response to extract guidelines and their relevance
          const matches = response.summary.match(/\d\.\s+(.*?)\nRelevance:\s+(.*?)\nConfidence:\s+(\d+)/gs);
          
          if (matches) {
            const parsedSuggestions = matches
              .map(match => {
                const [_, guideline, relevance, confidence] = match.match(/\d\.\s+(.*?)\nRelevance:\s+(.*?)\nConfidence:\s+(\d+)/s) || [];
                return {
                  guideline: guideline?.trim(),
                  relevance: relevance?.trim(),
                  confidence: parseInt(confidence || '0', 10)
                };
              })
              .filter(s => 
                s.guideline && 
                criteria.some(c => c.guideline === s.guideline)
              )
              .sort((a, b) => b.confidence - a.confidence);

            if (parsedSuggestions.length > 0) {
              setSuggestions(parsedSuggestions);
            } else {
              setError('No matching guidelines found');
            }
          } else {
            setError('Could not parse suggestions');
          }
        } else {
          setError('No suggestions could be generated');
        }
      } catch (error) {
        console.error('Error getting guideline suggestions:', error);
        setError('Failed to analyze guidelines');
      } finally {
        setLoading(false);
      }
    };

    analyzeClinicalNotes();
  }, [clinicalNotes, selectedLOB, criteria]);

  if (!clinicalNotes?.trim() || !selectedLOB) return null;

  return (
    <div className="mt-6 relative group">
      <div className="absolute inset-0 bg-gradient-to-r from-yellow-500/20 to-amber-500/20 rounded-xl blur-lg"></div>
      <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-4 rounded-xl">
        <div className="flex items-center gap-2 mb-4">
          <Lightbulb className="h-5 w-5 text-yellow-400" />
          <h3 className="text-sm font-medium text-white">Suggested Guidelines</h3>
        </div>
        
        {loading ? (
          <div className="flex items-center justify-center p-4">
            <Loader2 className="h-5 w-5 text-white/60 animate-spin" />
            <span className="ml-2 text-sm text-white/60">Analyzing clinical notes...</span>
          </div>
        ) : error ? (
          <div className="flex items-center gap-2 p-4 bg-red-500/20 rounded-lg">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <p className="text-sm text-red-300">{error}</p>
          </div>
        ) : (
          <div className="space-y-3">
            {suggestions.map((suggestion, index) => (
              <div 
                key={index}
                className="relative group"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-blue-500/10 rounded-lg blur-sm group-hover:blur-md transition-all duration-300"></div>
                <button
                  onClick={() => onSelectGuideline(suggestion.guideline)}
                  className="relative w-full text-left p-3 bg-white/5 hover:bg-white/10 rounded-lg transition-colors duration-200"
                >
                  <div className="flex justify-between items-start mb-2">
                    <h4 className="text-sm font-medium text-white/90 flex-1 pr-4">
                      {suggestion.guideline}
                    </h4>
                    <span className="px-2 py-1 text-xs font-medium rounded-full bg-green-500/20 text-green-300">
                      {suggestion.confidence}% match
                    </span>
                  </div>
                  <p className="text-sm text-white/70">
                    {suggestion.relevance}
                  </p>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuidelineSuggestions;