import React, { useCallback } from 'react';
import { AlertCircle, Eraser } from 'lucide-react';
import { useFormStore } from '../store/formStore';
import { useReviewStore } from '../store/reviewStore';
import DictationButton from './DictationButton';
import ImageUpload from './ImageUpload';
import SummarySection from './summary/SummarySection';
import AutoReadSummary from './AutoReadSummary';

interface MedicalHistoryInputProps {
  clinicalNotes: string;
  aiFocus: string;
  onClinicalNotesChange: (notes: string) => void;
  onAIFocusChange: (focus: string) => void;
}

const MedicalHistoryInput: React.FC<MedicalHistoryInputProps> = ({
  clinicalNotes,
  aiFocus,
  onClinicalNotesChange,
  onAIFocusChange
}) => {
  const { startTimer, timerActive } = useFormStore();
  const { incrementReviews } = useReviewStore();

  const handleClinicalNotesChange = useCallback((value: string) => {
    // Remove there/them/their references
    const cleanedValue = value
      .replace(/\b(?:there|them|their)\b/gi, '')
      .replace(/\s+/g, ' ')
      .trim();

    onClinicalNotesChange(cleanedValue);

    // Start timer and increment review count when notes are first added
    if (cleanedValue.trim() && !clinicalNotes.trim() && !timerActive) {
      startTimer();
      incrementReviews();
    }
  }, [onClinicalNotesChange, clinicalNotes, timerActive, startTimer, incrementReviews]);

  const handleClear = () => {
    onClinicalNotesChange('');
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="text-sm font-medium text-white">
            Clinical Notes
          </label>
          <button
            onClick={handleClear}
            className="flex items-center px-3 py-1.5 text-sm text-white/80 hover:text-white bg-red-500/20 hover:bg-red-500/30 rounded-lg transition-colors"
          >
            <Eraser className="h-4 w-4 mr-2" />
            Clear Notes
          </button>
        </div>
        <div className="relative">
          <textarea
            value={clinicalNotes}
            onChange={(e) => handleClinicalNotesChange(e.target.value)}
            rows={10}
            className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-white/50 focus:ring-2 focus:ring-indigo-500 resize-y transition-colors duration-200"
            placeholder="Enter clinical notes..."
            style={{ minHeight: '200px' }}
          />
          <div className="absolute bottom-2 right-2 flex items-center space-x-2">
            <DictationButton onTextReceived={handleClinicalNotesChange} />
            <ImageUpload onTextExtracted={handleClinicalNotesChange} />
          </div>
        </div>
        <div className="mt-4">
          <AutoReadSummary text={clinicalNotes} autoPlay={false} />
        </div>
        <div className="mt-4">
          <SummarySection text={clinicalNotes} />
        </div>
      </div>
      
      <div>
        <label className="block text-sm font-medium text-white mb-2">
          AI Focus Area (Optional)
          <span className="ml-2 text-white/60 text-xs">
            Guide AI on specific aspects to analyze
          </span>
        </label>
        <textarea
          value={aiFocus}
          onChange={(e) => onAIFocusChange(e.target.value)}
          rows={4}
          className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-white/50 focus:ring-2 focus:ring-indigo-500 mb-2"
          placeholder="E.g., 'Focus on cardiovascular history' or 'Analyze recent surgical procedures'"
        />
        <div className="bg-white/10 rounded-lg p-4 text-sm text-white/80">
          <div className="flex items-center mb-2">
            <AlertCircle className="h-4 w-4 mr-2 text-indigo-400" />
            <span className="font-medium">Tips for AI Focus:</span>
          </div>
          <ul className="list-disc list-inside space-y-1 ml-6">
            <li>Specify conditions or systems to prioritize</li>
            <li>Highlight specific time periods</li>
            <li>Request focus on particular treatments or procedures</li>
            <li>Ask for emphasis on specific symptoms or outcomes</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MedicalHistoryInput;