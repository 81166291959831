import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Settings, Users, CreditCard, Bell, BarChart, LogOut, Volume2, VolumeX, FileText } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useAudioStore } from '../../store/audioStore';
import VoiceSettings from '../voice/VoiceSettings';

const NavActions = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const { isMuted, toggleMute } = useAudioStore();
  const [showVoiceSettings, setShowVoiceSettings] = React.useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.warn('Logout cleanup:', error);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      {!user ? (
        <Link 
          to="/login"
          className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
        >
          Sign In
        </Link>
      ) : (
        <>
          <Link
            to="/ocr"
            className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
          >
            <FileText className="h-5 w-5 mr-2" />
            Document OCR
          </Link>

          <div className="relative">
            <button
              onClick={() => setShowVoiceSettings(!showVoiceSettings)}
              className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
            >
              {isMuted ? <VolumeX className="h-5 w-5" /> : <Volume2 className="h-5 w-5" />}
              <span className="ml-2">Voice Settings</span>
            </button>
            {showVoiceSettings && (
              <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg p-4 z-50">
                <VoiceSettings />
              </div>
            )}
          </div>

          {user.isAdmin && (
            <>
              <button
                onClick={() => navigate('/admin')}
                className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
              >
                <Settings className="h-5 w-5 mr-2" />
                Admin
              </button>
              <button
                onClick={() => navigate('/admin/subscribers')}
                className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
              >
                <Users className="h-5 w-5 mr-2" />
                Subscribers
              </button>
              <button
                onClick={() => navigate('/admin/billing')}
                className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
              >
                <CreditCard className="h-5 w-5 mr-2" />
                Billing
              </button>
              <button
                onClick={() => navigate('/admin/subscription-alerts')}
                className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
              >
                <Bell className="h-5 w-5 mr-2" />
                Alerts
              </button>
            </>
          )}

          <button
            onClick={() => navigate('/analytics')}
            className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
          >
            <BarChart className="h-5 w-5 mr-2" />
            Analytics
          </button>

          <button
            onClick={handleLogout}
            className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
          >
            <LogOut className="h-5 w-5 mr-2" />
            Logout
          </button>
        </>
      )}
    </div>
  );
};

export default NavActions;