import { useState, useCallback } from 'react';
import { OpenAIService } from '../../../services/openai/service';
import { aiResponseCache } from '../../../services/AIResponseCache';

export const useSummaryGeneration = () => {
  const [summary, setSummary] = useState('');
  const [keyFindings, setKeyFindings] = useState<string[]>([]);
  const [recommendations, setRecommendations] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateSummary = useCallback(async (text: string) => {
    if (!text?.trim()) {
      setError('Text input is required');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Try cache first
      const cached = await aiResponseCache.getResponse(text, 'clinical_summary');
      if (cached) {
        setSummary(cached);
        setIsLoading(false);
        return;
      }

      const response = await OpenAIService.analyzeClinicalNotes(
        text,
        'clinical_summary',
        []
      );

      if (!response.summary?.trim()) {
        throw new Error('No summary was generated');
      }

      setSummary(response.summary);
      setKeyFindings(response.keyFindings || []);
      setRecommendations(response.recommendations || []);
      
      // Cache successful response
      await aiResponseCache.setResponse(text, 'clinical_summary', response.summary);

    } catch (error) {
      console.error('Summary generation error:', error);
      setError(error instanceof Error ? error.message : 'Failed to generate summary');
      setSummary('');
      setKeyFindings([]);
      setRecommendations([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    summary,
    keyFindings,
    recommendations,
    isLoading,
    error,
    generateSummary
  };
};