import React, { useState, useEffect } from 'react';
import { Stethoscope } from 'lucide-react';
import { OpenAIService } from '../../services/openai/service';

interface DiagnosisSummaryProps {
  clinicalNotes: string;
}

const DiagnosisSummary: React.FC<DiagnosisSummaryProps> = ({ clinicalNotes }) => {
  const [summary, setSummary] = useState<string>('');
  const [diagnoses, setDiagnoses] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const extractDiagnoses = async () => {
      if (!clinicalNotes?.trim()) return;

      setLoading(true);
      setError(null);
      try {
        const response = await OpenAIService.analyzeClinicalNotes(
          clinicalNotes,
          'clinical_summary',
          []
        );

        setSummary(response.summary || '');
        if (response.keyFindings?.length) {
          setDiagnoses(response.keyFindings);
        } else {
          setError('No diagnoses found in clinical notes');
        }
      } catch (error) {
        console.error('Error extracting diagnoses:', error);
        setError('Failed to analyze diagnoses');
      } finally {
        setLoading(false);
      }
    };

    extractDiagnoses();
  }, [clinicalNotes]);

  if (!clinicalNotes?.trim()) return null;

  return (
    <div className="mt-4">
      <div className="flex items-center gap-2 mb-2">
        <Stethoscope className="h-5 w-5 text-green-400" />
        <h3 className="text-sm font-medium text-white">Diagnosis Summary</h3>
      </div>
      
      {loading ? (
        <div className="bg-white/10 rounded-lg p-4">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-2">
              <div className="h-4 bg-white/20 rounded"></div>
              <div className="h-4 bg-white/20 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      ) : error ? (
        <div className="bg-red-500/20 rounded-lg p-4">
          <p className="text-sm text-red-300">{error}</p>
        </div>
      ) : (
        <div className="bg-white/10 rounded-lg p-4">
          {summary && (
            <p className="text-sm text-white/90 mb-3 pb-3 border-b border-white/10">
              {summary}
            </p>
          )}
          <div className="space-y-2">
            {diagnoses.map((diagnosis, index) => (
              <p key={index} className="text-sm text-white/90">
                {diagnosis}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DiagnosisSummary;